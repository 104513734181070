<template>
  <div>
    <shop-head currentPageName="SINGLE PRODUCT"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Single Product"></breadcrumb>
    <div class="detail-box">
      <div class="container">
        <el-row :gutter="30" type="flex" style="flex-wrap: wrap">
          <el-col :md="12">
            <img
              :src="
                productData.urlList ? productData.urlList[imgIndex].big : ''
              "
              alt=""
              class="big-img"
            />
            <div class="product-switch">
              <slider-img
                @toggleImg="changeImg"
                :imgList="productData.urlList"
              ></slider-img>
            </div>
          </el-col>
          <el-col :md="12">
            <div class="price-box">
              ${{ $utils.keepTwoDecimalFull(productData.price) }}
            </div>
            <div class="sp-heading">{{ productData.name }}</div>
            <div class="rating-box" style="padding: 0">
              <i
                v-for="item in 4"
                :key="item"
                class="iconfont icon-pingfendengjiRating1"
              ></i>
              <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
              <span style="color: #333; margin-left: 10px"
                >(1 customer review)</span
              >
            </div>
            <!-- <div class="product-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla augue nec est tristique auctor. Donec non est at libero vulputate rutrum. Morbi ornare lectus quis justo gravida semper. Nulla tellus mi, vulputate adipiscing cursus eu, suscipit id nulla.</div> -->
            <!-- <div class="product-size_box">
              <div class="label">Size:</div>
              <el-select v-model="size" placeholder="请选择" style="width: 70px;">
                <el-option label="S" value="S"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="L" value="L"></el-option>
                <el-option label="XL" value="XL"></el-option>
              </el-select>
            </div> -->
            <div class="in-stock">
              <i class="el-icon-success"></i>
              <span>203 In Stock</span>
            </div>
            <div class="quantity-area">
              <div class="quantity">
                <label>Quantity</label>
                <el-input-number
                  v-model="quantity"
                  controls-position="right"
                  :min="1"
                  class="quantity-input"
                ></el-input-number>
              </div>
              <!-- <div @click="addToCart(productData.id)" class="quantity-add">Add To Cart</div> -->
            </div>
            <!-- <div class="qty-btn_area">
              <el-tooltip effect="dark" content="Add To Wishlist" placement="top">
                <div class="icon-btn">
                  <div class="icon-box">
                    <i class="el-icon-star-off"></i>
                  </div>
                <span>Add To Wishlist</span>
                </div>
              </el-tooltip>
            </div> -->
            <div class="category-list_area">
              <div>Category:</div>
              <ul class="tags-list">
                <!-- <li>Bedroom Furniture,</li>
                <li>Bedroom Furniture,</li>
                <li>Bedroom Furniture,</li>
                <li>Bedroom Furniture,</li>
                <li>Bedroom Furniture,</li> -->
              </ul>
            </div>
            <div class="category-list_area" style="padding-top: 20px">
              <div>Tags:</div>
              <ul class="tags-list">
                <!-- <li>Man</li> -->
              </ul>
            </div>
            <div class="social-box">
              <div
                @click="gotoShareWeb(item.url)"
                v-for="item in shareList"
                :key="item.title"
                class="social-item"
              >
                <el-tooltip effect="dark" :content="item.title" placement="top">
                  <i :class="['iconfont', item.iconName]"></i>
                </el-tooltip>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="sp-tab_area">
          <el-tabs v-model="detailTabIndex">
            <el-tab-pane label="Description" name="0">
              <div class="tab_content">
                <el-descriptions
                  title="Essential details"
                  border
                  :column="2"
                  size="small"
                >
                  <el-descriptions-item
                    v-for="(item, index) in productData.EssentialDetails"
                    :key="index"
                    :label="item.name"
                    >{{ item.val }}</el-descriptions-item
                  >
                </el-descriptions>
                <el-descriptions
                  title="Packaging & delivery"
                  border
                  :column="2"
                  size="small"
                  style="margin-top: 30px"
                >
                  <el-descriptions-item
                    v-for="(item, index) in productData.Packagingelivery"
                    :key="index"
                    :label="item.name"
                    >{{ item.val }}</el-descriptions-item
                  >
                </el-descriptions>
                <div class="detail-img">
                  <img
                    v-for="(item, index) in productData.urlList"
                    :key="index"
                    :src="item.big"
                    alt=""
                  />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Specification" name="1">
              <div class="tab_content">
                <el-descriptions border :column="2" size="small">
                  <el-descriptions-item
                    v-for="(item, index) in productData.specification"
                    :key="index"
                    :label="item.name"
                    >{{ item.val }}</el-descriptions-item
                  >
                </el-descriptions>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="Reviews (1)" name="2">Reviews (1)</el-tab-pane> -->
          </el-tabs>
        </div>
        <!-- <div class="product-tab_area">
          <div class="product-tab_nav">
            <div class="product-tab">
              <ul>
                <li v-for="(item,index) in partTab" :key="index" :class="{'active': index === partTabIndex}">{{ item.name }}</li>
              </ul>
            </div>
            <div class="product-box">
              <div v-for="item in partTab[partTabIndex].number" :key="item" class="part-item">
                <div class="product-img">
                  <img src="../../assets/images/partItem01.png" alt="">
                  <div class="add-action">
                    <ul>
                      <li @click="showQuickView">
                        <el-tooltip effect="dark" content="Add To Cart" placement="top">
                          <i class="el-icon-goods"></i>
                        </el-tooltip>
                      </li>
                      <li @click="showQuickView">
                        <el-tooltip effect="dark" content="Add To Wishlist" placement="top">
                          <i class="el-icon-star-off"></i>
                        </el-tooltip>
                      </li>
                      <li @click="showQuickView">
                        <el-tooltip effect="dark" content="Quick View" placement="top">
                          <i class="el-icon-search"></i>
                        </el-tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="part-item-price">$70.00<span>$80.00</span></div>
                <div class="part-item-text">Control Arm Suspensio</div>
                <div class="part-item-star">
                  <i v-for="item in 3" :key="item" class="iconfont icon-pingfendengjiRating1"></i>
                  <i class="iconfont icon-pingfendengjiRating7 gray"></i>
                  <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="swiper-box">
          <swiper
            :imgList="imgList"
            :imgWidth="124"
            :speed="1"
            :direction="'left'"
          ></swiper>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
    <el-dialog :visible.sync="showDetail" width="80%">
      <quick-view></quick-view>
    </el-dialog>
  </div>
</template>

<script>
import quickView from "../../components/shop/quickView";
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import swiper from "../../components/shop/swiper";
import sliderImg from "../../components/shop/sliderImg";
import breadcrumb from "../../components/common/breadcrumb";
export default {
  components: {
    quickView,
    shopHead,
    commonFooter,
    swiper,
    sliderImg,
    breadcrumb,
  },
  data() {
    return {
      showDetail: false,
      size: "S",
      quantity: 1,
      detailTabIndex: 0,
      shareList: [
        {
          title: "Facebook",
          iconName: "icon-mianshulogo",
          url: "https://www.facebook.com/p/BBmart-Auto-Parts-100082812802804/",
        },
        {
          title: "Twitter",
          iconName: "icon-twitter-square-fill",
          url: "https://twitter.com/",
        },
        {
          title: "Youtube",
          iconName: "icon-Youtube-fill",
          url: "https://www.youtube.com/@bbmartautoparts5963/featured",
        },
        {
          title: "Google Plus",
          iconName: "icon-googleplus-circle-f",
          url: "https://www.plus.google.com/discover",
        },
        {
          title: "Instagram",
          iconName: "icon-instagram",
          url: "https://www.instagram.com/bbmartautoparts/",
        },
      ],
      partTab: [
        {
          name: "Suspension Series",
          number: 5,
        },
      ],
      partTabIndex: 0,
      imgList: [
        require("../../assets/images/partIcon01.png"),
        require("../../assets/images/partIcon02.png"),
        require("../../assets/images/partIcon03.png"),
        require("../../assets/images/partIcon04.png"),
        require("../../assets/images/partIcon05.png"),
        require("../../assets/images/partIcon06.png"),
      ],
      productId: "",
      productData: {},
      imgIndex: 0,
    };
  },
  created() {
    this.productId = this.$route.query.id;
    let arr = JSON.parse(JSON.stringify(this.$productList.productList));
    this.productData = arr.filter((item) => {
      return item.id === this.productId;
    })[0];
  },
  methods: {
    /** 切换图片 */
    changeImg(index) {
      this.imgIndex = index;
    },
    /** 加入购物车 */
    addToCart(id) {
      let arr = [];
      if (window.localStorage.getItem("cartIdArr")) {
        arr = window.localStorage.getItem("cartIdArr").split(",");
      }
      let obj = {};
      if (window.localStorage.getItem("cartIdNum")) {
        obj = JSON.parse(window.localStorage.getItem("cartIdNum"));
      }
      if (arr.includes(id)) {
        obj[id].num++;
      } else {
        arr.push(id);
        obj[id] = {
          num: 1,
        };
        this.$store.commit("addCartCount");
      }
      window.localStorage.setItem("cartIdArr", arr.join());
      window.localStorage.setItem("cartIdNum", JSON.stringify(obj));
      this.$message.success("success");
    },
    /** 快速查看 */
    showQuickView() {
      this.showDetail = true;
    },
    /** 跳转url */
    gotoShareWeb(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-box {
  padding-top: 55px;

  .big-img {
    width: 100%;
    display: block;
    border: 1px solid #ebebeb;
  }

  .product-switch {
    margin-top: 30px;
  }

  .price-box {
    padding-bottom: 15px;
    font-size: 31px;
    line-height: 1;
    color: inherit;
    font-weight: 600;
  }

  .sp-heading {
    margin-bottom: 0;
    padding-bottom: 25px;
    font-size: 24px;
    font-weight: 400;
    text-align: justify;
  }

  .rating-box {
    width: 100%;
    font-size: 15px;
    color: #fed100;
    padding-bottom: 20px;

    .iconfont {
      font-size: 12px;
      margin-right: 5px;
    }

    .gray {
      color: #c0c0c0;
    }
  }

  .product-desc {
    font-size: 15px;
  }

  .product-size_box {
    padding-top: 25px;
    display: flex;
    align-items: center;

    .label {
      padding-right: 15px;
    }
  }

  .in-stock {
    padding-top: 25px;

    .el-icon-success {
      padding-right: 5px;
      color: #43ae2e;
    }
  }

  .quantity-area {
    display: flex;
    align-items: center;
    padding-top: 25px;

    .quantity-input {
      width: 90px;
      margin-left: 5px;

      ::v-deep .el-input__inner:focus {
        border-color: #fed100;
      }
    }

    .quantity-add {
      width: 140px;
      height: 40px;
      line-height: 40px;
      display: block;
      text-align: center;
      border-radius: 22px;
      font-weight: 600;
      border: 2px solid #fed100;
      margin-left: 15px;
      cursor: pointer;

      &:hover {
        background: #fed100;
        border-color: #fed100;
        color: #ffffff !important;
      }
    }
  }

  .qty-btn_area {
    padding: 30px 0;
    border-bottom: 1px solid #ebebeb;

    .icon-btn {
      &:hover {
        color: #fed100;
      }
    }

    .icon-box {
      width: 35px;
      height: 35px;
      line-height: 35px;
      color: #fff;
      font-size: 20px;
      text-align: center;
      background-color: #fed100;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }

  .social-box {
    padding-top: 30px;
    margin-top: 25px;
    border-top: 1px solid #ebebeb;

    .social-item {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      border: 1px solid #ebebeb;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .category-list_area {
    padding-top: 30px;
    display: flex;
    align-items: baseline;
    padding-top: 25px;

    .tags-list {
      padding-left: 5px;

      li {
        display: inline-block;
        color: #999;

        &:hover {
          color: #fed100;
        }
      }
    }
  }

  .sp-tab_area {
    padding-top: 55px;

    ::v-deep .el-tabs__item {
      font-size: 22px;
      padding: 0 30px 10px;
      font-weight: 400;
      color: #999999;

      &.is-active {
        color: #333;
      }
    }

    ::v-deep .el-tabs__active-bar {
      background-color: #333;
      height: 1px;
    }

    ::v-deep .el-tabs__nav-wrap {
      &::after {
        height: 1px;
      }
    }

    .tab_content {
      padding: 10px 0 25px;
      border-bottom: 1px solid #ebebeb;
    }

    .detail-img {
      img {
        width: 100%;
      }
    }
  }

  .product-tab_area {
    padding-top: 60px;

    .product-tab_nav {
      .product-tab {
        padding-bottom: 30px;

        ul {
          display: flex;
          justify-content: center;

          li {
            font-weight: 400;
            padding: 0 25px 15px;
            position: relative;
            margin-right: 15px;
            display: block;
            font-size: 28px;
            text-align: center;

            &.active {
              font-weight: 600;

              &::before {
                content: "";
                background-color: #fed100;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 6px;
                width: 48px;
                border-radius: 3px;
              }
            }
          }

          @media only screen and (max-width: 767px) {
            padding-bottom: 25px;

            li {
              margin-right: 0;
              padding: 0 25px 0 0;
              font-size: 18px;

              &.active::before {
                display: none;
              }
            }
          }

          @media only screen and (max-width: 479px) {
            li {
              padding: 0 10px 15px 0;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .product-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .part-item {
      width: 100%;
      height: auto;
      border: 1px solid #f4f4f4;
      box-sizing: border-box;
      padding: 30px 10px;
      margin: 0 0 29px;

      @media only screen and (min-width: 576px) {
        width: 49%;
        margin: 0 0.5% 29px;
      }

      @media only screen and (min-width: 768px) {
        width: 32.33%;
        margin: 0 0.5% 29px;
      }

      @media only screen and (min-width: 992px) {
        width: 24%;
        margin: 0 0.5% 29px;
      }

      @media only screen and (min-width: 1200px) {
        width: 19%;
        margin: 0 0.5% 29px;
      }

      .product-img {
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          margin: 0 auto 25px;
        }

        .add-action {
          text-align: center;
          position: absolute;
          bottom: 29px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;

          li {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            text-align: center;
            opacity: 0;
            background-color: #fed100;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            transition: all 0.3s ease-in;

            &:nth-child(1) {
              transition-delay: 100ms;
            }

            &:nth-child(2) {
              transition-delay: 150ms;
            }

            &:nth-child(3) {
              transition-delay: 200ms;
            }
          }
        }
      }

      &:hover {
        border-color: #fed100;

        .add-action {
          li {
            opacity: 1;
          }
        }
      }
    }

    .part-item-price {
      width: 100%;
      line-height: 1;
      font-size: 22px;
      color: #e01212;
      font-weight: 600;
      padding-bottom: 15px;
      box-sizing: border-box;

      span {
        font-size: 16px;
        font-weight: normal;
        color: #c0c0c0;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }

    .part-item-text {
      width: 100%;
      line-height: 1;
      font-size: 15px;
      padding-bottom: 5px;
      box-sizing: border-box;
    }

    .part-item-star {
      width: 100%;
      line-height: 24px;
      font-size: 12px;
      color: #fed100;
      padding-bottom: 10px;
      box-sizing: border-box;

      .gray {
        color: #c0c0c0;
      }
    }
  }

  .swiper-box {
    width: 100%;
    height: 141px;
    border: 1px solid #ebebeb;
    padding: 30px 0;
    margin: 31px 0 60px;
  }
}
</style>
